import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sharedLoggerApi } from 'shared-helpers';
import ErrorBoundaryWidget from '../micro-frontends/ErrorBoundaryWidget';

const loggerApi = sharedLoggerApi;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    async componentDidCatch(error, errorInfo) {
        const userId = this.props.user.user_id;
        const pagePath = this.props.location.pathname;

        await loggerApi.logError(userId, pagePath, error.toString());
    }

    render() {
        const { fallbackErrorMessage } = this.props;
        if (this.state.hasError) {
            if (fallbackErrorMessage) {
                return fallbackErrorMessage;
            }
            return <ErrorBoundaryWidget />;
        }
        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer
});

export default connect(mapStateToProps)(withRouter(ErrorBoundary));
